*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: gordita,Helvetica,sans-serif;
}

body{
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
    
}



header{
    background-color: #e51937;
    min-height: 10vh;
    display: flex;
   
}

header a{
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content:center;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

header a h2{
    font-size: 4rem;
    text-transform: uppercase;
}


header form {
    display: flex;
    align-items: center;
    flex: 1 1;
    padding: 1rem 0rem;
}


form input{
    padding-left: .2rem;
    height: 40%;
    width: 60%;
    border: none;
    outline: none;
    border-radius: 1rem;
    font-size: 1.5rem;
}

form button{

    margin-left: .5rem;
    font-size: 1.5rem;
    color: #fff !important;
	text-transform: uppercase;
	text-decoration: none;
	background: transparent;
	padding: .5rem 1rem;
	border-radius: 5px;
	display: inline-block;
	border: none;
	transition: all 0.4s ease 0s;
    cursor: pointer;
}

form button:hover{
    background: #434343;
	letter-spacing: 1px;
	-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
	transition: all 0.4s ease 0s;
}

.menuContainer{
    width: 90%;
    margin: auto; 
    display: flex;
    padding-top: 3rem;
    border-bottom: 2px solid hsla(0,0%,100%,.4);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
}

/*DropdownContainer*/

.optionsContainer{
    flex: 1 1;
}

.optionsContainer .options{
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    
}


.optionsContainer .options li{
    font-size: 2.5rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
   
}

.optionsContainer .options li a{
    font-size: 2.5rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    color:white;
    text-decoration: none;
}

.optionsContainer .options li a:after{
    content:'';
        position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background:currentColor;
        transform: scaleX(0);
        transform-origin:right;
        transition: transform 350ms ease-in-out;
        
}

.optionsContainer .options li:after{
    content:'';
        position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background:currentColor;
        transform: scaleX(0);
        transform-origin:right;
        transition: transform 350ms ease-in-out;
}


.optionsContainer .options li:hover::after{
    transform: scaleX(1);
}

.dropdownContainer{
    flex: .25 .25;
}
/* Dropdown Button */
.dropbtn {
    background-color:black;
    color: white;
    font-size: 3rem;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font-size: 2rem;
  }
  
  /* Links inside the dropdown */
  .dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #646464;}

.moviesList {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.moviesList div{
    margin-bottom: 4rem;
    position: relative;
    width: 30%;
    height: 100%;
}

.moviesList div img{
    height: 100%;
    display: block;
    width: 100%;
}

 .moviesList .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: column;
    padding-top: 30%;
    align-items: center;
    overflow:auto;
    opacity: 0;
    transition: opacity 0.25s;
 }

 .moviesList .overlay > *{
     transform: translateY(2rem);
     transition: transform 0.25s;
 }

.moviesList .overlay:hover{
    opacity: 1;
}

.moviesList .overlay:hover > *{
    transform: translateY(0)
}

.overlay .description{
    font-size: 1.5rem;
    line-height: 1.5;
    /* padding: 2rem; */
}

.overlay p{
margin-top: 1.5rem;
font-size: 1.5rem;
}


h2{
    width: 90%;
    text-align: center;
    font-size: 3rem;
}

 .overlay button{
     margin-top: 1.5rem;
    cursor: pointer;
    padding: .5rem .75rem;
    font-size: 1.5rem;
    font-weight: 500;
    border: .5px solid white;
    border-radius: 5px;
    background: rgb(47, 47, 47);
    color: white;
}

.overlay button:hover{
    background: #e51937;
    transition: 200ms ease-in;
}

.burger{
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 15%;
    right: 5%;
    z-index: 20;
    display: none;
    cursor: pointer;
}



.burger div{
    width: 3rem;
    height: 0.5rem;
    background-color: white;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

.close div:nth-child(1){
    transform: rotate(0);
}
.close div:nth-child(2){
    transform: translateX(0);
    opacity: 1;
}
.close div:nth-child(3){
    transform: rotate(0);
}


.open div:nth-child(1){
    transform: rotate(45deg);
}
.open div:nth-child(2){
    transform: translateX(100%);
    opacity: 0;
}
.open div:nth-child(3){
    transform: rotate(-45deg);
}

.active-menu{
    transform:translateX(0);
    display: flex;
}




@media (max-width: 767px){

    .inactive-menu{
        display: none;
         transform:translateX(100%);
     }

    .burger{
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }

    .optionsContainer .options{
        flex-flow: row nowrap;
        z-index:2;
        flex-flow: column nowrap;
        background-color: #112443;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 300px;
        padding-top: 3.5rem;
        transition: transform 0.3s ease-in-out;
       justify-content:space-evenly;
    }

    .optionsContainer .options li{
        font-size: 2rem;
        padding-left: 2rem;
    }

    .overlay .description{
        padding: none;
    }
    
}

@media (max-width: 420px) {
    .optionsContainer .options li{
        font-size: 3rem
    }

    .moviesList div{
        margin-bottom: 6rem;
        width: 100%;
    }

    .overlay .description{
        font-size: 1.5rem;
       padding: 0rem .7rem;
    }
    
    .overlay p{
   
    font-size: 1.5rem;
    }

    .overlay h2{
        font-size:3rem;
    }
    
    .overlay button{
        padding:.5rem .7rem ;
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }


}